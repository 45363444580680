export interface LoginInput {
  email?: string;
  phoneNumber?: string;
  password: string | null;
}

export interface loginOtpInput {
  phoneNumber: string;
  code?: string;
}

export interface RegisterInput extends LoginInput {}

export interface IVerifyCode extends Pick<LoginInput, 'email' | 'phoneNumber'> {
  code: string;
}

export interface IReSendDigitCode
  extends Pick<LoginInput, 'email' | 'phoneNumber'> {}

export interface ISendCodeForgotPassword
  extends Pick<LoginInput, 'email' | 'phoneNumber'> {}

export interface ISendGoogleToken {
  token: string;
}

export interface ISetNewPassword
  extends Pick<LoginInput, 'email' | 'phoneNumber'> {
  digitCode: string;
  newPassword: string;
}

export interface IRefreshTokenRequest {
  token: string;
  refreshToken: string;
}

export interface IAuthResponse {
  userId: string;
  qBankUserId: number;
  email: string;
  token: string;
  refreshToken: string;
  refreshTokenExpiryTime: string;
  isInfoFullySubmitted: boolean;
}

export interface IRegisterFillInfoForm {
  fullName: string;
  academicRank: string;
  email: string;
  password: string;
  rePassword: string;
}

export interface ILoginForm {
  credentials: string;
  password: string;
}

export interface ILoginOtpForm {
  credentials: string;
}

export interface IResetPasswordStep1Form {
  credentials: string;
}
export interface IResetPasswordStep3Form {
  password: string;
  rePassword: string;
}

export interface IGoogleCompleteInfoStep1Form {
  fullName: string;
  academicRank: string;
  phoneNumber: string;
}

export interface IGoogleCompleteInfoStep3Form {
  password: string;
  rePassword: string;
}

export interface ICompleteInfoForm {
  fullName: string;
  academicRank: string;
  email: string;
}

export enum ELoginType {
  PASSWORD = 'password',
  OTP = 'otp',
}

export enum EloginErrorMsg {
  NOT_FOUND = 'User not found',
  INVALID_PASS = 'Invalid password',
  EMAIL_NOT_VERIFIED = 'Email not verified',
}
