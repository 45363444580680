/* eslint-disable @next/next/no-img-element */
import { MuiltiCirclesBg, NurseImage2 } from '@/constant/imgImport';
import Typography from 'design-system/components/Typography/Typography';
import { memo } from 'react';

const LandingIllustrationOfAuthPage = ({
  centerImage = NurseImage2,
  title,
  description,
}: {
  centerImage: string;
  title: string;
  description: string;
}) => {
  return (
    <div className="flex flex-shrink-0 flex-col justify-end space-y-2 px-2 text-Text/Darkbackground/Primary xl:w-1/2 xl:space-y-[10px] xl:px-0 2xl:w-2/5">
      <Typography
        className="xl:mx-auto xl:w-[75%] xl:text-[40px] xl:font-bold xl:leading-[140%] xl:tracking-[0.45px]"
        variant={'display1'}
      >
        {title}
      </Typography>
      <Typography
        variant={'body2'}
        className="xl:mx-auto xl:w-[75%] xl:text-2xl xl:font-normal"
      >
        {description}
      </Typography>
      <div className="relative hidden w-full xl:block">
        <img
          src={MuiltiCirclesBg}
          alt="multi-circles-bg"
          className="absolute bottom-0 z-10 aspect-auto w-full"
        />

        <img
          src={centerImage}
          alt="nurse-bg"
          className="relative z-20 mx-auto w-[68%]"
        />
      </div>
    </div>
  );
};

export default memo(LandingIllustrationOfAuthPage);
