import {
  IDistrict,
  IPostRegisterCourseInRoadmap,
  IPostTrackMetricsForCampaign,
  IProvience,
} from '@/interfaces/utils.api.interface';
import { AxiosRequestConfig } from 'axios';
import { tmsHTTPRequest } from '../../services/axios/httpRequest';

export const getUrlPreview = (url: string) => {
  return tmsHTTPRequest.get(`/utils/url-preview?url=${url}`);
};

export const getSitemap = (
  sitemapType: string,
  options: AxiosRequestConfig
) => {
  return tmsHTTPRequest.get(`/utils/seo/sitemap?type=${sitemapType}`, options);
};

export const postRegisterCourseInRoadmap = (
  data: IPostRegisterCourseInRoadmap
) => tmsHTTPRequest.post<string>('/utils/courses/register', data);

export const postTrackMetricsForCampaign = (
  data: IPostTrackMetricsForCampaign
) => tmsHTTPRequest.post('/utils/campaign/track', data);

export const getProvinceList = (): Promise<Array<IProvience>> => {
  return tmsHTTPRequest.get('/utils/province');
};

export const getDistrictList = (
  provinceId: string
): Promise<Array<IDistrict>> => {
  return tmsHTTPRequest.get(`/utils/province/${provinceId}/district`);
};
