import BottomSectionOfAuthPage from '@/components/elements/auth/BottomSectionOfAuthPage';
import HeadingSectionOfAuthPage from '@/components/elements/auth/HeadingSectionOfAuthPage';
import LandingIllustrationOfAuthPage from '@/components/elements/auth/LandingIllustrationOfAuthPage';
import { NurseImage2 } from '@/constant/imgImport';
import React from 'react';

const AuthLayout = ({
  children,
  title,
  description,
  illustrationImage = NurseImage2,
  showLoginOptions,
}: {
  children: React.ReactNode;
  illustrationImage?: string;
  title: string;
  description: string;
  showLoginOptions?: boolean;
}) => {
  return (
    <div className="flex min-h-screen flex-col space-y-4 bg-Primary/Main p-2 xl:flex-row xl:space-y-0 xl:p-0">
      <LandingIllustrationOfAuthPage
        centerImage={illustrationImage}
        title={title}
        description={description}
      />
      <div className="flex flex-1 flex-col justify-between rounded-lg bg-Other/White p-4 xl:rounded-none xl:p-[40px_64px]">
        <HeadingSectionOfAuthPage />
        {children}
        <BottomSectionOfAuthPage showLoginOptions={showLoginOptions} />
      </div>
    </div>
  );
};

export default AuthLayout;
