import { generateQuery } from '@/helpers/pathName';
import { postGoogleLogin } from 'apis/tms/auth.api';
import { NextRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import { PATH_NAME } from '../configs/pathName';
import { IToastModal } from '../interfaces/components/modal.interface';
import { clearTMSCookies } from './authFunction';
import { campaignTracking } from './campaignTracking';
import redirectToFolderPage from './redirectToFolderPage';

export const handleSendGoogleIdToken = async ({
  idToken = '',
  toast,
  setLoading,
  router,
  disableRedirect = false,
  successCallback = (_userId: string) => {},
  setUserEmail,
}: {
  idToken: string | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
  toast: IToastModal;
  router: NextRouter;
  disableRedirect?: boolean;
  successCallback?: (_userId: string) => void;
  setUserEmail: (_email: string) => void;
}) => {
  const { nfcId, redirectUrl } = router.query;

  setLoading(true);
  try {
    const data = await postGoogleLogin({ token: idToken });

    clearTMSCookies();
    setUserEmail(data.email);

    campaignTracking({ origin: 'LOGIN' });

    toast.success({
      content: !disableRedirect
        ? 'Tiếp tục với Google thành công, vui lòng chờ trong giây lát để được chuyển hướng'
        : 'Tiếp tục với Google thành công',
      duration: 1500,
    });

    const condition = await redirectToFolderPage(router);
    if (condition) {
      setLoading(false);
      return;
    }

    if (data.isInfoFullySubmitted) {
      if (!disableRedirect) {
        router.replace(
          nfcId
            ? `${PATH_NAME.LIBRARY}?nfcId=${nfcId}`
            : (redirectUrl as string) || PATH_NAME.LIBRARY
        );
      }
      successCallback(data.qBankUserId.toString());
    } else {
      router.push({
        pathname: PATH_NAME.COMPLETE_INFO_PHONE,
        query: generateQuery(router),
      });
    }
  } catch (error) {
    toast.error({
      content: 'Có lỗi xảy ra khi tiếp tục với Google, vui lòng thử lại sau!',
      infinite: true,
    });
  } finally {
    setLoading(false);
  }
};
