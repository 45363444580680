import { DOCCEN_LOGO_ICON, DOCCEN_TYPO_ICON } from '@/constant/imgImport';
import { clearCookiesAndLocalStorage } from 'common/authFunction';
import { ZALO_OA_SUPPORT } from 'configs/externalLink';
import { PATH_NAME } from 'configs/pathName';
import { Button } from 'design-system/components/Button';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo, useContext } from 'react';
import AuthContext from 'state/auth/AuthContext';

const HeadingSectionOfAuthPage = () => {
  const router = useRouter();
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div className="flex justify-between">
      <div
        onClick={() => router.push(PATH_NAME.HOME)}
        className="flex cursor-pointer items-stretch"
      >
        <Image
          width={21}
          height={36}
          alt="doccen logo"
          src={DOCCEN_LOGO_ICON}
        />
        <Image
          width={72}
          height={36}
          alt="doccen typo icon"
          src={DOCCEN_TYPO_ICON}
        />
      </div>
      {isLoggedIn() ? (
        <div className="flex items-center gap-2">
          <Button
            onClick={() => window.open(ZALO_OA_SUPPORT)}
            size="small"
            emphasis="low"
            startIcon={{ name: 'question' }}
          >
            Trợ giúp
          </Button>
          <Button
            onClick={() => {
              clearCookiesAndLocalStorage();
              window.location.href = PATH_NAME.LOGIN;
            }}
            mode="icon-only"
            size="small"
            emphasis="low"
            startIcon={{ name: 'logout' }}
          />
        </div>
      ) : (
        <Button
          onClick={() => window.open(ZALO_OA_SUPPORT)}
          size="small"
          emphasis="low"
          startIcon={{ name: 'question' }}
        >
          Trợ giúp
        </Button>
      )}
    </div>
  );
};

export default memo(HeadingSectionOfAuthPage);
