import { generateQuery, QueryKeys } from '@/helpers/pathName';
import { ELoginType } from '@/interfaces/auth.interface';
import { handleSendGoogleIdToken } from 'common/handleSendGoogleIdToken';
import { PATH_NAME } from 'configs/pathName';
import { Button } from 'design-system/components/Button';
import { Divider } from 'design-system/components/Divider';
import { Typography } from 'design-system/components/Typography';
import { useRouter } from 'next/router';
import { memo, useContext } from 'react';
import GoogleLogin from 'react-google-login';
import { useQueryClient } from 'react-query';
import AuthContext from 'state/auth/AuthContext';
import { useModal } from 'state/modal-provider/ModalProvider';

const BottomSectionOfAuthPage = ({
  showLoginOptions = false,
}: {
  showLoginOptions?: boolean;
}) => {
  const router = useRouter();

  const navigateToLoginPage = (type: ELoginType) =>
    router.push({
      pathname: PATH_NAME.LOGIN,
      query: { ...generateQuery(router), [QueryKeys.type]: type },
    });

  return (
    <div className="space-y-3">
      {showLoginOptions && (
        <>
          <div className="mx-auto flex max-w-[600px] items-center gap-[15px] text-Text/LightBackground/Secondary">
            <Divider type="horizontal" className="flex-shrink-1" />
            <Typography variant={'body3'} className="flex-shrink-0">
              Hoặc đăng nhập bằng
            </Typography>
            <Divider type="horizontal" className="flex-shrink-1" />
          </div>
          <div className="flex justify-center gap-4">
            <Button
              onClick={() => navigateToLoginPage(ELoginType.OTP)}
              size="medium"
              mode="icon-only"
              icon={{ name: 'zalo' }}
            />
            <Button
              onClick={() => navigateToLoginPage(ELoginType.PASSWORD)}
              size="medium"
              mode="icon-only"
              icon={{ name: 'call', variant: 'solid' }}
            />
            <Button
              onClick={() => navigateToLoginPage(ELoginType.PASSWORD)}
              size="medium"
              mode="icon-only"
              icon={{ name: 'mail_filled' }}
            />
            <LoginGoogleButton />
          </div>
        </>
      )}
      <Typography
        className="text-center text-Text/LightBackground/Secondary"
        variant={'input-label'}
      >
        ©2024 All Rights Reserved.&nbsp;
        <br className="xl:hidden" />
        Doccen Technology Joint Stock Company
      </Typography>
    </div>
  );
};

function LoginGoogleButton() {
  const router = useRouter();
  const { toast } = useModal();
  const { setLoading, setUserEmail } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const successLoginGoogle = (res: any) => {
    const { tokenId } = res;
    handleSendGoogleIdToken({
      idToken: tokenId,
      setLoading,
      toast,
      router,
      setUserEmail,
      successCallback(_userId) {
        queryClient.refetchQueries({ active: true });
      },
    });
  };

  const failLoginGoogle = async ({ error }: { error: string }) => {
    if (
      error !== 'popup_closed_by_user' &&
      error !== 'idpiframe_initialization_failed'
    ) {
      toast.error({
        content: 'Có lỗi xảy ra khi tiếp tục với Google, vui lòng thử lại sau!',
        infinite: true,
      });
    }
  };

  return (
    <GoogleLogin
      clientId={process.env.GOOGLE_ID || ''}
      render={(renderProps) => (
        <Button
          {...renderProps}
          size="medium"
          mode="icon-only"
          icon={{ name: 'google' }}
        />
      )}
      onSuccess={successLoginGoogle}
      onFailure={failLoginGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
}

export default memo(BottomSectionOfAuthPage);
