import { checkNullish } from '@/helpers/checkNullish';
import { IPostTrackMetricsForCampaign } from '@/interfaces/utils.api.interface';
import { postTrackMetricsForCampaign } from 'apis/tms/utils.api';

const key = 'campaignAffiliateRefKey';

export const setCampaignAffiliateToLocalstorage = (ref: string) => {
  localStorage.setItem(key, ref);
};

export function campaignTracking(
  defaultData: Partial<IPostTrackMetricsForCampaign>
) {
  const campaignId =
    defaultData.campaignId || checkNullish(localStorage.getItem(key));
  if (campaignId) {
    const data: IPostTrackMetricsForCampaign = {
      ...defaultData,
      campaignId,
    };

    postTrackMetricsForCampaign(data);
  }
}
